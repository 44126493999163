
body {
  padding: 0;
  margin: 0;
  background-color: hsla(0,0%,0%,1);
  font-family: 'Rubik';
}

.mainTitle {
  font-size: 90.0784px;
  color: white;
  text-align: center;
}

.cursor {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  user-select: none;
  opacity: .6;
  background: radial-gradient(circle at var(--x, 0%) var(--y, 0%), rgba(45, 36, 85, .90) 1%, transparent 100%);
}
