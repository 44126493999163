.home .hero {
  background-image: url('../../assets/images/hero-banner.png');
  background-size: cover;
  color: white;
  text-align: center;
  padding: 50px 0;
}

.home .hero h2 {
  font-size: 2rem;
}

.home .hero button {
  background-color: #f0c040;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.home .profile {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.home .profile img {
  max-width: 200px;
  margin-right: 20px;
}

.home .how-i-can-help {
  padding: 20px;
}
